import {consola} from "consola";
import {useIZNewsUtils} from "./useIZNewsUtils";
import {TypeNewsDto} from "./news.type";

export const useIZNewsDetail = () => {

  const globalSettings = inject("globalSettings");
  const {getItems} = useDirectusItems();
  const {generateNewsDto, generateNewsCategoryDto, logger} = useIZNewsUtils(globalSettings);

  const getSubscriptionCategories = async (languageCode: string) => {

    return getItems({
      collection: "news_category",
      params: {
        fields: [
          "id",
          "status",
          "sort",
          "parent_id",
          "show",
          "display_mode",
          "show_subscription",
          "translations.languages_code",
          "translations.title",
          "translations.slug",
          // "translations.seo_details.*",
          "translations.seo_detail.*",
        ],
        filter: {
          _and: [
            {
              status: {
                _eq: "published"
              }
            },
            {
              show_subscription: {
                _eq: true
              }
            }

          ]
        },
      }
    }).then(
      (res: any) => {
        return res.map((ite: any) => {
          return generateNewsCategoryDto(ite, languageCode);
        })
      }).catch((e: any) => {
      logger.error("Error: getSubscriptionCategories: ", e);
      return [];
    })
  }

  const getNewsDetailBySlug = async (languageCode: string,
                                     seo_fields: string[] = [],
                                     options: { slug: string, categorySlug: string}) => {
    return getItems({
      collection: "news",
      params: {
        fields: [
          "id",
          "status",
          "date_created",
          "sort",
          "platform",
          "on_top",
          "hero",
          "tags.tags_id.*",
          "tags.tags_id.translations.languages_code",
          "tags.tags_id.translations.title",
          "category.id",
          "category.status",
          "category.show",
          "category.display_mode",
          "category.show_subscription",
          "category.translations.id",
          "category.translations.languages_code",
          "category.translations.title",
          "category.translations.slug",
          "category.translations.intro",
          "translations.languages_code",
          "translations.title",
          "translations.content",
          "translations.intro",
          "translations.slug",
          "translations.thumbnail.id",
          "translations.thumbnail.title",
          "translations.thumbnail.description",
          // 'translations.seo_details.*',
          'translations.seo_detail.*',
          ...seo_fields
        ],
        filter: {
          _and: [
            {
              status: {
                _eq: "published"
              }
            },
            {
              translations: {
                languages_code: {
                  _eq: languageCode
                }
              }
            },
            {
              translations: {
                slug: options.slug
              }
            },
            {
              category: {
                translations: {
                  slug: options.categorySlug
                }
              }
            }
          ]
        },
      }
    }).then((data: any) => {
      return {model: generateNewsDto(data[0], languageCode) as TypeNewsDto};
    }).catch(e => {
      logger.error("Error: getNewsDetail: ", e);
      return {};
    })
  }

  return {
    getNewsDetailBySlug,
    getSubscriptionCategories,
    logger
  }
}
